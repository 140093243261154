import { useState, useEffect, useCallback } from "react";
import Box from "@mui/system/Box";
import { getDiagnosisCodes } from "../services/orders";
import { DataGridPro } from "@mui/x-data-grid-pro";

import { TextField } from "@mui/material";
import { DataTableContainerNoButton } from "./TableContainer";

export default function DiagnosisPanel({ }) {
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 50,
    });
    const [diagnosiss, setDiagnosiss] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [rowCount, setRowCount] = useState(0);
    const [rowCountState, setRowCountState] = useState(rowCount);
    const [filter, setFilter] = useState("");

    useEffect(() => {
        console.log(paginationModel);
        setLoading(true);
        const abortController = new AbortController();
        const signal = abortController.signal;
        getDiagnosisCodes(
            filter,
            paginationModel.pageSize,
            paginationModel.page,
            signal
        )
            .then((p) => {
                if (!p.ok) {
                    throw new Error("Failed to load diagnosiss");
                }
                return p.json();
            })
            .then((p) => {
                if (!signal.aborted) {
                    setLoading(false);
                    setDiagnosiss(p.DiagnosisCodes);
                    setRowCount(p.Count);
                }
            })
            .catch((e) => {
                if (!signal.aborted) {
                    setError(e.message);
                    setLoading(false);
                }
            });
        return () => {
            abortController.abort();
        }
    }, [paginationModel, filter]);

    useEffect(() => {
        // needed to keep position in pages
        setRowCountState((prevRowCountState) =>
            rowCount !== undefined ? rowCount : prevRowCountState
        );
    }, [rowCount, setRowCountState]);

    const handleFilterChange = (value) => {
        setFilter(value);
    };

    const columns = [
        { field: "Code", headerName: "Code", width: 100 },
        { field: "OrderNumber", headerName: "Order Number", width: 110 },
        { field: "Description", headerName: "Description", width: 575 },
    ];

    const QuickFilterToolbar = (props) => {
        const { onFilterChange } = props;

        const handleChange = useCallback(
            (event) => {
                const value = event.target.value;
                setFilter(value);
                onFilterChange(value);
            },
            [onFilterChange]
        );

        // useEffect(() => {
        // return apiRef.current.subscribeToStateChange(
        // ({ api, state, type }) => {
        // if (type === "columnFilterChange") {
        // setFilterValue("");
        // }
        // },
        // { columnFilterChange: true }
        // );
        // }, [apiRef]);

        return (
            <TextField
                // value={filter}
                onBlur={handleChange}
                placeholder="Quick Filter"
                variant="standard"
            />
        );
    };

    return (
        <DataTableContainerNoButton>
            <DataGridPro
                getRowId={(row) => row.Code}
                error={error}
                pagination
                density="compact"
                loading={loading}
                filterMode="server"
                components={{ Toolbar: QuickFilterToolbar }}
                componentsProps={{
                    toolbar: { onFilterChange: handleFilterChange },
                }}
                paginationMode="server"
                page={paginationModel.page}
                pageSize={paginationModel.pageSize}
                setPageSize={50}
                onPageChange={(page) =>
                    setPaginationModel({ ...paginationModel, page: page })
                }
                onPageSizeChange={(pageSize) =>
                    setPaginationModel({
                        ...paginationModel,
                        pageSize: pageSize,
                    })
                }
                onPaginationModelChange={setPaginationModel}
                paginationModel={paginationModel}
                rowCount={rowCountState}
                rows={diagnosiss}
                columns={columns}
            />
        </DataTableContainerNoButton>
    );
}
