import ReflexesTable from "../tables/ReflexesTable";
import { Stack } from "@mui/system";
import { Button } from "@mui/material";
import { useState, useEffect } from "react";
import { getReflex } from "../services/tests";
import ReflexForm from "./ReflexEdit";
import TableFrame from "./TableFrame";
import { DataTableContainer } from "./TableContainer";
import ButtonTray from "./ButtonTray";

export default function ReflexesPanel({ }) {
    const [view, setView] = useState("table");
    const [reflex, setReflex] = useState();
    const [reflexDetails, setReflexDetails] = useState({
        Type: "test2test",
        SourceSubtests: [],
    });
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        if (reflex !== undefined) {
            getReflex(reflex[0]).then((p) => {
                p.SourceTest = p.SourceTestID;
                p.DestTest = p.DestTestID;
                p.SourceProfile = p.SourceProfileID;
                p.DestProfile = p.DestProfileID;
                p.SourceSubtests = p.SourceSubtests.map((s) => s.ID);
                p.Facilities = p.Facilities.map((f) => f.ID);
                setReflexDetails(p);
            });
        }
    }, [reflex]);

    if (view === "table") {
        return (
            <>
                <TableFrame>
                    <DataTableContainer>
                        <ReflexesTable
                            reflexes={reflex}
                            setReflexes={setReflex}
                            checkboxes={false}
                            refresh={refresh}
                        />
                    </DataTableContainer>
                    <ButtonTray>
                        <Button
                            variant="contained"
                            color="success"
                            onClick={() => {
                                setReflex(undefined);
                                setReflexDetails({
                                    Type: "test2test",
                                    SourceSubtests: [],
                                });
                                setView("create");
                            }}
                        >
                            Create
                        </Button>
                        <Button
                            disabled={!reflex}
                            variant="contained"
                            color="primary"
                            onClick={() => setView("edit")}
                        >
                            Edit
                        </Button>
                    </ButtonTray>
                </TableFrame>
            </>
        );
    } else if (view === "create") {
        const saved = () => { setView("table"); setRefresh(!refresh) };
        return (
            <ReflexForm
                reflexDetails={reflexDetails}
                setReflexDetails={setReflexDetails}
                saved={saved}
            />
        );
    } else if (view === "edit") {
        const saved = () => { setView("table"); setRefresh(!refresh) };
        return (
            <ReflexForm
                reflexDetails={reflexDetails}
                setReflexDetails={setReflexDetails}
                saved={saved}
            />
        );
    }
}
