import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { placeOfService, stateAbbreviations } from "../services/util";
import { Formik, Form, useFormikContext } from "formik";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import { Button, Stack } from "@mui/material";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { getProviders } from "../services/providers";
import { addFacility, updateFacility } from "../services/facilities";
import { getContacts } from "../services/contacts";
import { getCollectors } from "../services/collectors";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import { getCityState } from "../services/usps";
import { CustomCheckboxField, CustomTextField } from "./CustomTextField";
import { apiFetch, useFetch } from "../services/fetch";
import { financialClassIndex } from "../services/util";
import { FrequencyVerifierAlgorithmIndex, ReportOrderPreferenceIndex } from "../constants/constants";
import TestsTable from "./TestsTable";

export default function FacilityInput({ initialValues, reset }) {
    const [allContacts, setAllContacts] = useState([]);
    const [allCategories, setAllCategories] = useState([]);
    const [allProviders, setAllProviders] = useState([]);
    const [allCollectors, setAllCollectors] = useState([]);
    const [zipCode, setZipCode] = useState("");

    const { data: feeSchedules, isLoading } = useFetch("/feeschedules");

    const GetCityState = () => {
        const { setFieldValue, setFieldError } = useFormikContext();
        useEffect(() => {
            if (zipCode.length === 5 || zipCode.length === 10) {
                getCityState(zipCode)
                    .then((data) => {
                        setFieldValue("Address.City", data.city);
                        setFieldValue("Address.State", data.state);
                    })
                    .catch((err) => {
                        setFieldError("Address.ZipCode", err.message);
                    });
            }
        }, [zipCode]);
    };

    const addressSchema = Yup.object().shape({
        Address1: Yup.string().required(),
        Address2: Yup.string().nullable(),
        City: Yup.string().required(),
        State: Yup.string().required(),
        ZipCode: Yup.string().required(),
    });

    const validationSchema = Yup.object().shape({
        Name: Yup.string().required(),
        Phone: Yup.string().matches(
            /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
            "Phone number is not valid"
        ),
        Fax: Yup.string().matches(
            /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
            "Fax number is not valid"
        ),
        Address: addressSchema,
        Active: Yup.bool(),
        CanSelfPay: Yup.bool(),
        CanClientBill: Yup.bool(),
        CanInsurance: Yup.bool(),
        CanNoBill: Yup.bool(),
        ReportOrderPreference: Yup.number().oneOf([0, 1]).required(),
        FrequencyAlgorithmID: Yup.number().oneOf([0, 1]).required(),
        POS: Yup.string().required(),
        NPI: Yup.string(),
        FEIN: Yup.string(),
        Providers: Yup.array(),
        Contacts: Yup.array(),
        Collectors: Yup.array(),
    });

    useEffect(() => {
        getProviders().then((p) => {
            setAllProviders(p);
        });
        getContacts().then((p) => {
            setAllContacts(p);
        });
        getCollectors().then((p) => {
            setAllCollectors(p);
        });
        const init = async () => {
            try {
                const response = await apiFetch(`/categories/`)
                setAllCategories(response)
            } catch (e) {
                console.log(e)
            }
        }

        init()
    }, []);

    const handleSubmit = async (values) => {
        values = validationSchema.cast(values)

        if (values.ID === undefined) {
            // create new facility
            console.log("createing");
            await addFacility(values);
            reset();
        } else {
            // update existing facility
            console.log("updating");
            await updateFacility(values, values.ID);
            reset();
        }
    };

    const concatName = (row) => {
        return row.row.FirstName + " " + row.row.LastName;
    };

    const categoryColumns = [
        { field: "Code", headerName: "Code", width: 200 },
        { field: "Name", headerName: "Name", width: 200 },
    ];

    const providerColumns = [
        {
            field: "Name",
            headerName: "Name",
            width: 200,
            valueGetter: concatName,
        },
        { field: "NPI", headerName: "NPI", width: 200 },
    ];

    const getCheckbox = (value) => {
        if (value.value) {
            return <NotificationImportantIcon color="error" />;
        }
        return <NotificationImportantIcon color="disabled" />;
    };

    const toTitle = (str) => {
        return str.value.toProperCase();
    };

    const contactColumns = [
        { field: "FirstName", headerName: "First Name", width: 125 },
        { field: "LastName", headerName: "Last Name", width: 125 },
        { field: "Type", headerName: "Type", width: 125, valueGetter: toTitle },
        { field: "Phone1", headerName: "Phone1", width: 125 },
        { field: "Email", headerName: "Email", width: 175 },
        {
            field: "Critical",
            headerName: "Critical Contact",
            width: 150,
            renderCell: getCheckbox,
            align: "center",
        },
    ];

    const collectorColumns = [
        { field: "ID", headerName: "ID", width: 45 },
        { field: "FirstName", headerName: "First Name", width: 150 },
        { field: "LastName", headerName: "Last Name", width: 150 },
        { field: "Phone", headerName: "Phone", width: 150 },
        { field: "Email", headerName: "Email", width: 200 },
        {
            field: "IsPhlebotomist",
            headerName: "Is Phlebotomist",
            width: 160,
            renderCell: getCheckbox,
            align: "center",
        },
    ];

    const CheckboxField = ({ values, name, handleChange, label }) => (
        <FormControlLabel
            control={
                <Checkbox
                    name={name}
                    checked={values?.[name]}
                    onChange={handleChange}
                />
            }
            label={label || name}
        />
    );

    return (
        <Box>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({ values, setFieldValue, touched, errors, handleChange }) => (
                    <Form>
                        {console.log('values', values)}
                        <GetCityState />
                        <Typography sx={{ my: 2 }} variant="h5" gutterBottom>
                            Facility
                        </Typography>
                        <Stack direction={"column"} spacing={2}>
                            <Stack
                                direction={{ sm: "column", md: "row" }}
                                spacing={2}
                            >
                                <CustomTextField required={true} name="Name" />
                                <CustomTextField
                                    name="NPI"
                                    inputProps={{
                                        type: "number",
                                        minLength: 10,
                                        maxLength: 10,
                                        pattern: "d",
                                    }}
                                />
                                <CustomTextField name="FEIN" />
                                <TextField
                                    select
                                    required={true}
                                    name="POS"
                                    size="small"
                                    label="Place Of Service"
                                    sx={{ minWidth: "200px" }}
                                    onChange={handleChange}
                                    value={values?.POS}
                                    touched={touched}
                                    errors={errors}
                                >
                                    {placeOfService.map((pos) => (
                                        <MenuItem value={pos.Code}>
                                            {pos.Code} - {pos.Name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Stack>
                            <Stack
                                direction={{ sm: "column", md: "row" }}
                                spacing={2}
                            >
                                <CustomTextField name="Phone" />
                                <CustomTextField name="Fax" />
                            </Stack>
                            <InputLabel>Address</InputLabel>
                            <Stack
                                direction={{ sm: "column", md: "row" }}
                                spacing={2}
                            >
                                <CustomTextField
                                    required={true}
                                    name="Address.Address1"
                                    label="Address 1"
                                    value={values?.Address?.Address1}
                                />
                                <CustomTextField
                                    name="Address.Address2"
                                    label="Address 2"
                                    value={values?.Address?.Address2}
                                />
                                <TextField
                                    required
                                    name={"Address.ZipCode"}
                                    label="ZipCode"
                                    helperText={
                                        touched?.Address?.ZipCode &&
                                        errors?.Address?.ZipCode
                                    }
                                    error={
                                        touched?.Address?.ZipCode &&
                                        Boolean(errors?.Address?.ZipCode)
                                    }
                                    size="small"
                                    // onBlur={formik.handleBlur}
                                    onChange={(e) => {
                                        setFieldValue(
                                            "Address.ZipCode",
                                            e.target.value
                                        );
                                        setZipCode(e.target.value);
                                    }}
                                    value={values?.Address?.ZipCode}
                                />
                                <CustomTextField
                                    name="Address.City"
                                    label="City"
                                    value={values?.Address?.City}
                                />
                                <Autocomplete
                                    disablePortal
                                    required
                                    options={stateAbbreviations}
                                    sx={{ minWidth: "223px", maxWidth: "100%" }}
                                    name="Address.State"
                                    value={values.Address?.State || null}
                                    onChange={(_, value) => {
                                        setFieldValue("Address.State", value);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            required
                                            error={
                                                touched.Address?.State &&
                                                Boolean(errors.Address?.State)
                                            }
                                            helperText={
                                                touched.Address?.City &&
                                                errors.Address?.City
                                            }
                                            name="State"
                                            size="small"
                                            label="State"
                                        />
                                    )}
                                />
                            </Stack>
                            <InputLabel>Allowed Payment Methods</InputLabel>
                            <Stack direction="row" spacing={2}>
                                <CheckboxField
                                    values={values}
                                    name="CanSelfPay"
                                    label="Self Pay"
                                    handleChange={handleChange}
                                />
                                <CheckboxField
                                    values={values}
                                    name="CanClientBill"
                                    label="Client Bill"
                                    handleChange={handleChange}
                                />
                                <CheckboxField
                                    values={values}
                                    name="CanInsurance"
                                    label="Insurance"
                                    handleChange={handleChange}
                                />
                                <CheckboxField
                                    values={values}
                                    name="CanNoBill"
                                    label="No Bill"
                                    handleChange={handleChange}
                                />
                            </Stack>
                            <InputLabel>Order Requirements</InputLabel>
                            <Stack direction="row" spacing={2}>
                                <CustomCheckboxField
                                    values={values}
                                    name="RequirePatientSignature"
                                    label="Require Patient Signature"
                                    onChange={(_, v) => setFieldValue("RequirePatientSignature", v)}
                                />
                                <CustomCheckboxField
                                    values={values}
                                    name="RequireIDCapture"
                                    label="Require ID Capture"
                                    onChange={(_, v) => setFieldValue("RequireIDCapture", v)}
                                />
                                <CustomCheckboxField
                                    values={values}
                                    name="RequireInsuranceCapture"
                                    label="Require Insurance Card Capture"
                                    onChange={(_, v) => setFieldValue("RequireInsuranceCapture", v)}
                                />
                                <CustomCheckboxField
                                    values={values}
                                    name="RequirePatientPhotoCapture"
                                    label="Add Patient Photo Capture"
                                    onChange={(_, v) => setFieldValue("RequirePatientPhotoCapture", v)}
                                />
                                <>
                                    <TextField
                                        select
                                        required={true}
                                        name="FrequencyAlgorithmID"
                                        size="small"
                                        label="Frequency Verifier Algorithm"
                                        sx={{ minWidth: "200px" }}
                                        onChange={(e) => {
                                            setFieldValue(
                                                "FrequencyAlgorithmID",
                                                e.target.value
                                            );
                                        }}
                                        value={String(values?.FrequencyAlgorithmID) || ""}
                                        touched={touched}
                                        errors={errors}
                                    >
                                        {Object.entries(FrequencyVerifierAlgorithmIndex)
                                            .map(([v, l]) => (
                                                <MenuItem key={v} value={v}>
                                                    {l}
                                                </MenuItem>
                                            ))}
                                    </TextField>
                                </>
                            </Stack>
                            <InputLabel>Result Report Preference</InputLabel>
                            <Stack direction="row" spacing={2}>
                                <TextField
                                    select
                                    required={true}
                                    name="ReportOrderPreference"
                                    size="small"
                                    label="Report Order"
                                    sx={{ minWidth: "200px" }}
                                    onChange={(e) => {
                                        setFieldValue(
                                            "ReportOrderPreference",
                                            e.target.value
                                        );
                                    }}
                                    value={String(values?.ReportOrderPreference) || ""}
                                    touched={touched}
                                    errors={errors}
                                >
                                    {Object.entries(ReportOrderPreferenceIndex)
                                        .map(([v, l]) => (
                                            <MenuItem key={v} value={v}>
                                                {l}
                                            </MenuItem>
                                        ))}
                                </TextField>
                            </Stack>

                            {values?.CanClientBill && !isLoading && (
                                <>
                                    <InputLabel>
                                        Client Bill Fee Schedule
                                    </InputLabel>
                                    <TextField
                                        select
                                        required={true}
                                        name="FeeSchedule"
                                        size="small"
                                        label="Fee Schedule"
                                        sx={{ minWidth: "200px" }}
                                        onChange={(e) => {
                                            setFieldValue(
                                                "FeeScheduleID",
                                                e.target.value
                                            );
                                        }}
                                        value={values?.FeeScheduleID || ""}
                                        touched={touched}
                                        errors={errors}
                                    >
                                        {feeSchedules
                                            .filter(
                                                (fs) =>
                                                    financialClassIndex[
                                                    fs.FinancialClass
                                                    ] === "Client Bill"
                                            )
                                            .map((fs) => (
                                                <MenuItem value={fs.ID}>
                                                    {fs.Name}
                                                </MenuItem>
                                            ))}
                                    </TextField>
                                </>
                            )}
                            <InputLabel>Category Filter</InputLabel>
                            <Box height="200px">
                                <DataGrid
                                    getRowId={(row) => row.ID}
                                    density="compact"
                                    rows={allCategories}
                                    columns={categoryColumns}
                                    pageSize={15}
                                    hideFooter
                                    components={{ Toolbar: GridToolbar }}
                                    componentsProps={{
                                        toolbar: {
                                            showQuickFilter: true,
                                            quickFilterProps: {
                                                debounceMs: 500,
                                            },
                                        },
                                    }}
                                    rowSelectionModel={values?.CategoryFilter || []}
                                    onRowSelectionModelChange={(newValues) => {
                                        setFieldValue("CategoryFilter", [
                                            ...newValues,
                                        ]);
                                    }}
                                    keepNonExistentRowsSelected
                                    pageSizeOptions={[15]}
                                    checkboxSelection
                                    disableRowSelectionOnClick
                                />
                            </Box>
                            <InputLabel>Test Filter</InputLabel>
                            <Box height="400px">
                                <TestsTable
                                    test={values?.TestFilter}
                                    setTest={(t) => setFieldValue("TestFilter", t)}
                                    checkboxes={true}
                                />
                            </Box>

                            <InputLabel>Ordering Providers</InputLabel>
                            <Box height="400px">
                                <DataGrid
                                    getRowId={(row) => row.ID}
                                    density="compact"
                                    rows={allProviders}
                                    columns={providerColumns}
                                    pageSize={15}
                                    components={{ Toolbar: GridToolbar }}
                                    componentsProps={{
                                        toolbar: {
                                            showQuickFilter: true,
                                            quickFilterProps: {
                                                debounceMs: 500,
                                            },
                                        },
                                    }}
                                    rowSelectionModel={values?.Providers}
                                    onRowSelectionModelChange={(newValues) => {
                                        setFieldValue("Providers", [
                                            ...newValues,
                                        ]);
                                    }}
                                    keepNonExistentRowsSelected
                                    pageSizeOptions={[15]}
                                    checkboxSelection
                                    disableRowSelectionOnClick
                                />
                            </Box>
                            <InputLabel>Associated Contacts</InputLabel>
                            <Box height="400px">
                                <DataGrid
                                    density="compact"
                                    getRowId={(row) => row.ID}
                                    rows={allContacts}
                                    columns={contactColumns}
                                    pageSize={15}
                                    components={{ Toolbar: GridToolbar }}
                                    componentsProps={{
                                        toolbar: {
                                            showQuickFilter: true,
                                            quickFilterProps: {
                                                debounceMs: 500,
                                            },
                                        },
                                    }}
                                    rowSelectionModel={values?.Contacts}
                                    onRowSelectionModelChange={(newValues) => {
                                        setFieldValue("Contacts", [
                                            ...newValues,
                                        ]);
                                    }}
                                    keepNonExistentRowsSelected
                                    pageSizeOptions={[15]}
                                    checkboxSelection
                                    disableRowSelectionOnClick
                                />
                            </Box>
                            <InputLabel>Associated Collectors</InputLabel>
                            <Box height="400px">
                                <DataGrid
                                    density="compact"
                                    getRowId={(row) => row.ID}
                                    rows={allCollectors}
                                    columns={collectorColumns}
                                    pageSize={15}
                                    components={{ Toolbar: GridToolbar }}
                                    componentsProps={{
                                        toolbar: {
                                            showQuickFilter: true,
                                            quickFilterProps: {
                                                debounceMs: 500,
                                            },
                                        },
                                    }}
                                    rowSelectionModel={values?.Collectors}
                                    onRowSelectionModelChange={(newValues) => {
                                        setFieldValue("Collectors", [
                                            ...newValues,
                                        ]);
                                    }}
                                    pageSizeOptions={[15]}
                                    keepNonExistentRowsSelected
                                    checkboxSelection
                                    disableRowSelectionOnClick
                                />
                            </Box>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                            >
                                <Button
                                    variant="outlined"
                                    sx={{ my: 2 }}
                                    onClick={reset}
                                >
                                    Back
                                </Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{ my: 2 }}
                                    onClick={() => console.log(values)}
                                >
                                    Save
                                </Button>
                            </Stack>
                        </Stack>
                    </Form>
                )}
            </Formik>
        </Box>
    );
}
