import React, { ReactNode, useEffect, useState } from "react";
import {
    Box,
    Grid,
    Paper,
    Typography,
    useMediaQuery
} from "@mui/material"
import { useFormikContext } from 'formik';
import RecentTestTable from "./OrderRecentTestsTable";
import { Patient, Profile, PaymentMethod, Reflex } from "../../constants/types";
import OrderTestsProfileTable from "./OrderProfilesTable";
import OrderTestsTable from "./OrderTestsTable";
import mdTheme from "../Theme";
import { searchReflexes } from "../../services/tests";
import ReflexesTable from "../../tables/ReflexesTable";
import { OrderValues } from "./OrderForm";

const StyledPaper = ({ children }: { children: ReactNode }) => {
    return <Paper
        elevation={10}
        sx={{
            p: 2,
            display: "block",
            flexDirection: "row",
            maxWidth: "100%",
        }}
    >
        {children}
    </Paper>
}

function OrderTestsStep() {
    const { values, setFieldValue } = useFormikContext<OrderValues>();
    const [recentTests, setRecentTests] = useState<any[]>([])
    const [profileDetails, setProfileDetails] = useState<Profile[]>([])
    const [availableReflexes, setAvailableReflexes] = useState<Reflex[]>([]);

    const isMobile = useMediaQuery(mdTheme.breakpoints.down("lg"));

    const setTests = (v: any) => setFieldValue('Tests', v)

    const setProfiles = (v: any) => {
        const selectedProfiles = profileDetails.filter((profile: Profile) =>
            v.includes(profile?.ID)
        );

        // select all tests for a selected profile
        const selectedTests = selectedProfiles.flatMap((profile: Profile) =>
            profile.Tests.map((test) => test.ID)
        );

        setFieldValue('Profiles', v)
        setFieldValue('Tests', [...selectedTests])
    }

    const setTestDetails = (v: any) => setFieldValue('TestDetails', v)
    const setReflexes = (v: any) => setFieldValue('Reflexes', v)
    console.log('values', values)

    useEffect(() => {
        searchReflexes(values?.Tests, values?.Profiles).then((reflexes) => {
            // Step 1: Filter reflexes for the current facility
            let filteredReflexes = reflexes.filter(
                (reflex: any) =>
                    !reflex?.Facilities ||
                    reflex?.Facilities.length === 0 ||
                    reflex?.Facilities.some((f: any) => f.ID === values?.Facility?.ID)
            );

            // Step 2: Iteratively validate reflexes to ensure correctness
            const validReflexSet = new Set();
            let previousSize = -1;

            // Repeat until no new reflexes are added to the valid set
            while (validReflexSet.size !== previousSize) {
                previousSize = validReflexSet.size;

                filteredReflexes.forEach((reflex: any) => {
                    if (
                        values?.Tests.includes(reflex?.SourceTestID) || // Direct inclusion
                        Array.from(validReflexSet).some(
                            (otherReflex: any) => reflex?.SourceTestID === otherReflex?.DestTestID
                        )
                    ) {
                        validReflexSet.add(reflex);
                    }
                });
            }

            // Convert set back to array
            filteredReflexes = Array.from(validReflexSet);

            setFieldValue('ReflexDetails', [])
            setReflexes([])
            for (let reflex of reflexes) {
                if (reflex?.AutoOrder) {
                    setFieldValue('ReflexDetails', [...values?.ReflexDetails, reflex])
                    setReflexes([...values?.Reflexes, reflex?.ID])
                }
            }

            setAvailableReflexes(filteredReflexes);
        });
    }, [values?.Tests, values?.Profiles]);

    const handleReset = () => {
        setTests([]);
        setProfiles([]);
        setTestDetails([]);
        setReflexes([]);
    }

    const handleRecentTestSelection = (tests: number[]) => {
        if (tests.length < values?.Tests.length) {
            // test was removed
            const removedTest = values?.Tests.find(
                (item) => !tests.includes(item)
            );

            setTests(values?.Tests.filter((item) => item !== removedTest));
        } else {
            const addedTests = tests.filter(
                (testId) => !values?.Tests.includes(testId)
            );
            setTests([...tests, ...addedTests]);
        }
    }

    let profilesTableHeight = 520;
    let testsTableHeight = 475;
    if (isMobile) {
        profilesTableHeight = 1000;
        testsTableHeight = 800;
    }

    return (
        <>
            <Box width={"100%"} >
                <Grid container spacing={2}>
                    {recentTests.length > 0 && (
                        <Grid item xs={12}>
                            <StyledPaper>
                                <Typography variant="h6" gutterBottom>
                                    Select Recent Tests
                                </Typography>
                                <RecentTestTable
                                    recentTests={recentTests}
                                    setRecentTests={setRecentTests}
                                    patientId={values?.Patient?.ID}
                                    setSelection={handleRecentTestSelection} />
                            </StyledPaper>
                        </Grid>)}
                    <Grid item xs={12} lg={4} xl={3}>
                        <Box alignSelf="center">
                            <StyledPaper>
                                <Typography variant="h6" gutterBottom>
                                    Select Profiles
                                </Typography>
                                <Box sx={{ minHeight: '300px', height: `calc(100vh - ${profilesTableHeight}px)` }}>
                                    <OrderTestsProfileTable
                                        facility={values?.Facility}
                                        setProfileDetails={setProfileDetails}
                                        // @ts-ignore
                                        selected={values?.Profiles}
                                        setSelected={setProfiles} />
                                </Box>
                            </StyledPaper>
                        </Box>
                    </Grid>
                    <Grid item xs={12} lg={8} xl={9}>
                        <StyledPaper>
                            <Box alignSelf="center" sx={{ minHeight: '300px', height: `calc(100vh - ${testsTableHeight}px)` }}>
                                <OrderTestsTable
                                    // @ts-ignore
                                    facility={values?.Facility}
                                    handleReset={handleReset}
                                    // @ts-ignore
                                    setTests={setTests}
                                    tests={values?.Tests}
                                />
                            </Box>
                        </StyledPaper>
                    </Grid>
                    <Grid item xs={12}>
                        <Box alignSelf="center">
                            {availableReflexes.length > 0 ? (
                                <Paper
                                    elevation={10}
                                    sx={{
                                        p: 2,
                                        display: "block",
                                        flexDirection: "row",
                                        maxWidth: "100%",
                                    }}
                                >
                                    <Typography variant="h6" gutterBottom>
                                        Select Reflexes
                                    </Typography>
                                    <ReflexesTable
                                        reflexes={values?.Reflexes}
                                        setReflexes={(r: any) => setFieldValue("Reflexes", r)}
                                        checkboxes={true}
                                        reflexSource={availableReflexes}
                                        setReflexDetails={() => { }}
                                        refresh={null}
                                    />
                                </Paper>
                            ) : (
                                <></>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    )

}

export default OrderTestsStep;
