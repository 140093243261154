import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FacilitySetup from "../components/FacilitySetup";
import TabPanel, { a11yProps } from "../components/TabPanel";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ProviderSetup from "../components/ProviderSetup";
import ContactSetup from "../components/ContactSetup";
import CollectorSetup from "../components/CollectorSetup";
import UserSetup from "../components/UserSetup";
import PatientOrderLinkSetup from "../components/PatientOrderLinkSetup";
import Framework from "../components/Framework";
import { useTabState } from "../components/useTabState";
import ProviderNoteTriggerSetup from "../components/ProviderNoteTriggerSetup";
import FacilityReportServiceMappings from "../tables/facilityservicereports";
import OrganizationsPanel from "../components/Organizations";

export default function Facilities() {
    const [tabValue, setTabValue] = useTabState(0)

    const tabs = [
        { label: "Facilities", component: <FacilitySetup /> },
        { label: "Organizations", component: <OrganizationsPanel /> },
        { label: "Providers", component: <ProviderSetup /> },
        { label: "Contacts", component: <ContactSetup /> },
        { label: "Collectors", component: <CollectorSetup /> },
        { label: "Users", component: <UserSetup /> },
        { label: "Patient Order Links", component: <PatientOrderLinkSetup /> },
        { label: "Provider Note Triggers", component: <ProviderNoteTriggerSetup /> },
        { label: "Facility Report Services", component: <FacilityReportServiceMappings /> }

    ]

    const handleChange = (_, newValue) => {
        setTabValue(newValue);
    };
    return (
        <Framework table>
            <Box sx={{ pl: 2, pr: 2, pt: 1 }}>
                <Typography variant="h5">Facilities</Typography>
                <Box
                    sx={{
                        borderBottom: 1,
                        borderColor: "divider",
                    }}
                >
                    <Tabs
                        value={tabValue}
                        variant="scrollable"
                        onChange={handleChange}
                        aria-label="basic tabs example"
                    >
                        {tabs.map((tab, index) => (

                            <Tab label={tab.label} {...a11yProps(index)} />
                        ))}
                    </Tabs>
                </Box>
            </Box>
            {tabs.map((tab, index) => (
                <TabPanel value={tabValue} index={index}>
                    {tab.component}
                </TabPanel>
            ))}
        </Framework>
    );
}
