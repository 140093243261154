import { Divider, FormGroup, Typography } from "@mui/material";
import Select from "@mui/material/Select";
import { useMediaQuery } from "@mui/material";
import React, { useTheme } from "@emotion/react";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { FormControl, FormControlLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import TestsTable from "../tables/TestsTable";
import ProfilesTable from "../tables/ProfilesTable";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Stack } from "@mui/system";
import { addReflex, getTests, updateReflex } from "../services/tests";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { reflexTypes } from "../services/util";
import { apiFetch } from "../services/fetch";
import { GridToolbar, DataGridPro } from "@mui/x-data-grid-pro";
import FacilitiesTable from "./FacilitiesTable";
import { getFacilities } from "../services/facilities";

function TextF(props) {
    return (
        <TextField
            size="small"
            sx={{ maxWidth: "200px", m: 1, display: "flex" }}
            {...props}
        />
    );
}

function isUndefined(obj, name) {
    if (obj === undefined || obj === null) {
        return "";
    } else if (obj[name] === undefined || obj[name] === null) {
        return "";
    }
    return obj[name];
}

function NumericRange({ ranges, setRanges, index }) {
    const [cutoff, setCutoff] = useState(false);
    const range = ranges[index];

    console.log(ranges);
    const updateField = (event) => {
        const r = [...ranges];
        r[index] = {
            ...ranges[index],
            [event.target.name]: event.target.value,
        };
        console.log("setting to ", r);
        setRanges(r);
    };

    return (
        <>
            <Divider sx={{ p: 1, my: 1 }} />
            <FormControl sx={{ flexDirection: "row", flexWrap: "wrap" }}>
                <TextF
                    required
                    name="AgeFrom"
                    label="Age From"
                    value={isUndefined(range, "AgeFrom")}
                    inputProps={{ type: "number", min: 0, max: 150 }}
                    sx={{ maxWidth: "80px", m: 1 }}
                    onChange={(event) => {
                        if (
                            !isNaN(event.target.value) &&
                            !isNaN(parseInt(event.target.value))
                        ) {
                            updateField({
                                target: {
                                    name: "AgeFrom",
                                    value: parseInt(event.target.value),
                                },
                            });
                        } else if (event.target.value === "") {
                            updateField({
                                target: { name: "AgeFrom", value: null },
                            });
                        }
                    }}
                />
                <TextF
                    required
                    name="AgeTo"
                    label="Age To"
                    value={isUndefined(range, "AgeTo")}
                    inputProps={{ type: "number", min: 0, max: 150 }}
                    sx={{ maxWidth: "80px", m: 1 }}
                    onChange={(event) => {
                        if (
                            !isNaN(event.target.value) &&
                            !isNaN(parseInt(event.target.value))
                        ) {
                            updateField({
                                target: {
                                    name: "AgeTo",
                                    value: parseInt(event.target.value),
                                },
                            });
                        } else if (event.target.value === "") {
                            updateField({
                                target: { name: "AgeTo", value: null },
                            });
                        }
                    }}
                />
                <FormControl sx={{ minWidth: 75, m: 1 }} size="small">
                    <InputLabel required shrink>
                        Sex
                    </InputLabel>
                    <Select
                        size="small"
                        name="Sex"
                        value={isUndefined(range, "Sex")}
                        onChange={updateField}
                    >
                        <MenuItem key={0} value={"A"}>
                            All
                        </MenuItem>
                        <MenuItem key={1} value={"M"}>
                            Male
                        </MenuItem>
                        <MenuItem key={2} value={"F"}>
                            Female
                        </MenuItem>
                    </Select>
                </FormControl>
                <TextF
                    required={
                        !(
                            isUndefined(range, "Cutoff") === true &&
                            !(isUndefined(range, "ValueTo") === "")
                        )
                    }
                    disabled={
                        isUndefined(range, "Cutoff") === true &&
                        !(isUndefined(range, "ValueTo") === "")
                    }
                    name="ValueFrom"
                    label="Value From"
                    value={isUndefined(range, "ValueFrom")}
                    inputProps={{ type: "number", step: 0.01 }}
                    onChange={(event) => {
                        if (
                            !isNaN(event.target.value) &&
                            !isNaN(parseFloat(event.target.value))
                        ) {
                            updateField({
                                target: {
                                    name: "ValueFrom",
                                    value: parseFloat(event.target.value),
                                },
                            });
                        } else if (event.target.value === "") {
                            updateField({
                                target: { name: "ValueFrom", value: null },
                            });
                        }
                    }}
                />
                <TextF
                    required={
                        !(
                            isUndefined(range, "Cutoff") === true &&
                            !(isUndefined(range, "ValueFrom") === "")
                        )
                    }
                    disabled={
                        isUndefined(range, "Cutoff") === true &&
                        !(isUndefined(range, "ValueFrom") === "")
                    }
                    name="ValueTo"
                    label="Value To"
                    value={isUndefined(range, "ValueTo")}
                    inputProps={{ type: "number", step: "0.01" }}
                    onChange={(event) => {
                        if (
                            !isNaN(event.target.value) &&
                            !isNaN(parseFloat(event.target.value))
                        ) {
                            updateField({
                                target: {
                                    name: "ValueTo",
                                    value: parseFloat(event.target.value),
                                },
                            });
                        } else if (event.target.value === "") {
                            updateField({
                                target: { name: "ValueTo", value: null },
                            });
                        }
                    }}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            name="Cutoff"
                            checked={isUndefined(range, "Cutoff")}
                            disabled={
                                isUndefined(range, "ValueFrom") &&
                                isUndefined(range, "ValueTo")
                            }
                            onChange={(v) => {
                                updateField({
                                    target: {
                                        name: "Cutoff",
                                        value: v.target.checked,
                                    },
                                });
                            }}
                        />
                    }
                    labelPlacement="bottom"
                    label="Cutoff"
                ></FormControlLabel>
                <IconButton
                    color="error"
                    sx={{ float: "right" }}
                    onClick={() => {
                        if (ranges.length > 1) {
                            const r = [...ranges];
                            r.splice(index, 1);
                            console.log("spliced to", r);
                            setRanges(r);
                        }
                    }}
                >
                    <RemoveCircleOutlineIcon />
                </IconButton>
                <IconButton
                    color="primary"
                    sx={{ float: "right" }}
                    onClick={() => {
                        const r = [...ranges];
                        r.push(1);
                        setRanges(r);
                    }}
                >
                    <ControlPointIcon />
                </IconButton>
            </FormControl>
        </>
    );
}

function ListRange({ ranges, setRanges, index }) {
    const range = ranges[index];

    console.log(ranges);
    const updateField = (event) => {
        const r = [...ranges];
        r[index] = {
            ...ranges[index],
            [event.target.name]: event.target.value,
        };
        console.log("setting to ", r);
        setRanges(r);
    };

    return (
        <>
            <Divider sx={{ p: 1, my: 1 }} />
            <FormControl sx={{ flexDirection: "row", flexWrap: "wrap" }}>
                <TextF
                    required
                    name="AgeFrom"
                    label="Age From"
                    value={isUndefined(range, "AgeFrom")}
                    inputProps={{ type: "number", min: 0, max: 150 }}
                    sx={{ maxWidth: "80px", m: 1 }}
                    onChange={(event) => {
                        if (
                            !isNaN(event.target.value) &&
                            !isNaN(parseInt(event.target.value))
                        ) {
                            updateField({
                                target: {
                                    name: "AgeFrom",
                                    value: parseInt(event.target.value),
                                },
                            });
                        } else if (event.target.value === "") {
                            updateField({
                                target: { name: "AgeFrom", value: null },
                            });
                        }
                    }}
                />
                <TextF
                    required
                    name="AgeTo"
                    label="Age To"
                    value={isUndefined(range, "AgeTo")}
                    inputProps={{ type: "number", min: 0, max: 150 }}
                    sx={{ maxWidth: "80px", m: 1 }}
                    onChange={(event) => {
                        if (
                            !isNaN(event.target.value) &&
                            !isNaN(parseInt(event.target.value))
                        ) {
                            updateField({
                                target: {
                                    name: "AgeTo",
                                    value: parseInt(event.target.value),
                                },
                            });
                        } else if (event.target.value === "") {
                            updateField({
                                target: { name: "AgeTo", value: null },
                            });
                        }
                    }}
                />
                <FormControl sx={{ minWidth: 75, m: 1 }} size="small">
                    <InputLabel required shrink>
                        Sex
                    </InputLabel>
                    <Select
                        size="small"
                        name="Sex"
                        value={isUndefined(range, "Sex")}
                        onChange={updateField}
                    >
                        <MenuItem key={0} value={"A"}>
                            All
                        </MenuItem>
                        <MenuItem key={1} value={"M"}>
                            Male
                        </MenuItem>
                        <MenuItem key={2} value={"F"}>
                            Female
                        </MenuItem>
                    </Select>
                </FormControl>
                <TextF
                    required
                    name="Value"
                    label="Value"
                    value={isUndefined(range, "Value")}
                    onChange={updateField}
                />
                <IconButton
                    color="error"
                    sx={{ float: "right" }}
                    onClick={() => {
                        if (ranges.length > 1) {
                            const r = [...ranges];
                            r.splice(index, 1);
                            console.log("spliced to", r);
                            setRanges(r);
                        }
                    }}
                >
                    <RemoveCircleOutlineIcon />
                </IconButton>
                <IconButton
                    color="primary"
                    sx={{ float: "right" }}
                    onClick={() => {
                        const r = [...ranges];
                        r.push(1);
                        setRanges(r);
                    }}
                >
                    <ControlPointIcon />
                </IconButton>
            </FormControl>
        </>
    );
}

function ReferenceRange({ type, ranges, setRanges }) {
    console.log(type, ranges);
    if (ranges === undefined) {
        return;
    }
    if (type === undefined || type === 0) {
        return <></>;
    } else if (type === "ListRange") {
        if (ranges.length === 0) {
            return (
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <ListRange
                        setRanges={setRanges}
                        ranges={ranges}
                        index={0}
                    />
                </Box>
            );
        } else {
            // list range
            return (
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    {ranges.map((v, i) => (
                        <ListRange
                            setRanges={setRanges}
                            ranges={ranges}
                            index={i}
                        />
                    ))}
                </Box>
            );
        }
    } else if (type === "NumericRange") {
        if (ranges.length === 0) {
            return (
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <NumericRange
                        setRanges={setRanges}
                        ranges={ranges}
                        index={0}
                    />
                </Box>
            );
        } else {
            // list range
            return (
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    {ranges.map((v, i) => (
                        <NumericRange
                            setRanges={setRanges}
                            ranges={ranges}
                            index={i}
                        />
                    ))}
                </Box>
            );
        }
    }
    return <></>;
}

function saveReflex(reflexDetails, ranges) {
    console.log("saving", reflexDetails);
    const m = { ...reflexDetails };

    switch (reflexDetails.ReflexRangeType) {
        case "ListRange":
            m.ReflexRangeList = ranges;
            break;
        case "NumericRange":
            m.ReflexRangeNumeric = ranges;
            break;
        default:
            break;
    }

    if (Array.isArray(reflexDetails.DestTest)) {
        m.DestTest = reflexDetails.DestTest[0];
    }
    if (Array.isArray(reflexDetails.SourceTest)) {
        m.SourceTest = reflexDetails.SourceTest[0];
    }
    if (Array.isArray(reflexDetails.SourceProfile)) {
        m.SourceProfile = reflexDetails.SourceProfile[0];
    } else {
        m.SourceProfile = m.SourceProfileID;
    }
    if (Array.isArray(reflexDetails.DestProfile)) {
        m.DestProfile = reflexDetails.DestProfile[0];
    } else {
        m.DestProfile = m.DestProfileID;
    }
    if (reflexDetails.ID) {
        console.log("updating", m);
        return updateReflex(m, reflexDetails.ID);
    } else {
        console.log("creating", m);
        return addReflex(m);
    }
}

function SubtestTable({ tests, selected, setSelected }) {
    const columns = [
        { field: "Code", headerName: "Code", width: 70 },
        { field: "Name", headerName: "Name", width: 220 },
        { field: "Description", headerName: "Description", width: 220 },
    ]

    return (
        <DataGridPro
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            getRowId={(row) => row.ID}
            pagination
            pageSize={50}
            density="compact"
            sx={{
                "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                {
                    display: "none",
                },
            }}
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                },
            }}
            initialState={{
                sorting: {
                    sortModel: [{ field: "__check__", sort: "desc" }],
                },
            }}
            keepNonExistentRowsSelected
            onRowSelectionModelChange={setSelected}
            rowSelectionModel={selected}
            checkboxSelection={true}
            rows={tests}
            columns={columns}
        />
    );

}

export default function ReflexForm({ reflexDetails, setReflexDetails, saved }) {
    const [ranges, setRanges] = useState([]);
    const [source, setSource] = useState(
        reflexDetails.SourceTestID !== null ? "Test" : "Profile"
    );
    const [dest, setDest] = useState(
        reflexDetails.DestTestID !== null ? "Test" : "Profile"
    );
    const [subtests, setSubtests] = useState([]);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [forAllFacs, setForAllFacs] = useState(
        reflexDetails.Facilities === undefined
            ? true
            : reflexDetails.Facilities.length === 0
    );

    useEffect(() => {
        console.log(reflexDetails);
        switch (reflexDetails.ReflexRangeType) {
            case "ListRange":
                if (reflexDetails.ReflexRangeList === undefined) {
                    break;
                }
                setRanges(reflexDetails.ReflexRangeList);
                break;
            case "NumericRange":
                if (reflexDetails.ReflexRangeNumeric === undefined) {
                    break;
                }
                setRanges(reflexDetails.ReflexRangeNumeric);
                break;
            default:
                break;
        }
    }, [reflexDetails.ReflexRangeType]);

    console.log('details', reflexDetails);
    console.log('subtests', subtests);
    console.log('test');

    useEffect(() => {
        setReflexDetails({
            ...reflexDetails,
            ReflexType: reflexTypes[source + "2" + dest],
        });
    }, [source, dest]);

    useEffect(() => {
        console.log('reflex details', reflexDetails)
        if (!reflexDetails.SourceTest) {
            console.log('returning')
            return
        }

        console.log('getting test')
        const getSubTest = async (test) => {
            if (test === null) {
                return
            }

            const response = await apiFetch("/tests/" + test);
            setSubtests(response.SubTests);
        }

        getSubTest(reflexDetails.SourceTest)

    }, [reflexDetails]);

    const updateReflex = (event) => {
        setReflexDetails({
            ...reflexDetails,
            [event.target.name]: event.target.value,
        });
    };


    return (
        <Box component="form" id="reflex-form">
            <Stack direction="column" spacing={2}>
                <TextField
                    onBlur={updateReflex}
                    defaultValue={reflexDetails.Description}
                    required
                    fullWidth
                    multiline
                    size="small"
                    rows={2}
                    name="Description"
                    label="Description"
                />
                <Stack direction="stack" spacing={2}>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox
                            checked={reflexDetails?.AutoOrder}
                            onChange={(e) => setReflexDetails({
                                ...reflexDetails,
                                AutoOrder: e.target.checked
                            })}
                        />} label="Auto Order" />
                    </FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="AllFacilities"
                                checked={forAllFacs}
                                onChange={(v) =>
                                    setForAllFacs(v.target.checked)
                                }
                            />
                        }
                        label="For All Facilities"
                    ></FormControlLabel>
                </Stack>
                <Grid
                    container
                    spacing={2}
                    alignItems="flex-start"
                    sx={{ width: "100%" }}
                >
                    <Grid item xs={12} md={5}>
                        <Stack spacing={2} direction="column">
                            <Typography variant="h5">Source</Typography>
                            <FormControl>
                                <TextField
                                    select
                                    size="small"
                                    value={source}
                                    label="Source"
                                    sx={{ width: "140px" }}
                                    onChange={(_, v) =>
                                        setSource(v.props.value)
                                    }
                                >
                                    <MenuItem value={"Test"}>Test</MenuItem>
                                    <MenuItem value={"Profile"}>
                                        Profile
                                    </MenuItem>
                                </TextField>
                            </FormControl>
                            <Box>
                                {source === "Test" ? (
                                    <>
                                        <Box height="400px">
                                            <TestsTable
                                                tests={
                                                    reflexDetails.SourceTest === null
                                                        ? []
                                                        : [reflexDetails.SourceTest]
                                                }
                                                getTests={getTests}
                                                setTestDetails={() => { }}
                                                setTests={(v) =>
                                                    setReflexDetails({
                                                        ...reflexDetails,
                                                        SourceTest: v.length > 0 ? v[0] : null
                                                    })
                                                }
                                                checkboxes={false}
                                            />
                                        </Box>
                                        {subtests.length > 0 && (
                                            <Stack mt={2} spacing={2} direction="column">
                                                <Typography variant="h5">Select Subtests (Optional)</Typography>
                                                <Typography variant="caption">If no subtests are selected, all subtests will be used.</Typography>
                                                <Box height="400px">
                                                    <SubtestTable
                                                        tests={subtests}
                                                        selected={reflexDetails.SourceSubtests}
                                                        setSelected={(v) => setReflexDetails({ ...reflexDetails, SourceSubtests: v })}
                                                    />
                                                </Box>
                                            </Stack>
                                        )}
                                    </>
                                ) : (
                                    <Box height="400px">
                                        <ProfilesTable
                                            checkboxes={false}
                                            profiles={
                                                reflexDetails.SourceProfile === null
                                                    ? []
                                                    : reflexDetails.SourceProfile
                                            }
                                            setProfiles={(v) =>
                                                setReflexDetails({
                                                    ...reflexDetails,
                                                    SourceProfile: v,
                                                })
                                            }
                                        />
                                    </Box>
                                )}
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid
                        item
                        xs={0}
                        md={2}
                        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                    // justifySelf="center"
                    // alignSelf="center"
                    >
                        {!isMobile ? (
                            <ArrowForwardIcon sx={{ fontSize: 75 }} />
                        ) : (
                            <ArrowDownwardIcon sx={{ fontSize: 75 }} />
                        )}
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Stack spacing={2} direction="column">
                            <Typography variant="h5">Reflexes To</Typography>
                            <FormControl>
                                <TextField
                                    select
                                    value={dest}
                                    label="Reflex To"
                                    size="small"
                                    sx={{ width: "140px" }}
                                    onChange={(_, v) => setDest(v.props.value)}
                                >
                                    <MenuItem value={"Test"}>Test</MenuItem>
                                    <MenuItem value={"Profile"}>
                                        Profile
                                    </MenuItem>
                                </TextField>
                            </FormControl>
                            <Box>
                                {dest === "Test" ? (
                                    <Box height="400px">
                                        <TestsTable
                                            tests={
                                                reflexDetails.DestTest === null
                                                    ? []
                                                    : reflexDetails.DestTest
                                            }
                                            setTestDetails={() => { }}
                                            getTests={getTests}
                                            setTests={(v) =>
                                                setReflexDetails({
                                                    ...reflexDetails,
                                                    DestTest: v,
                                                })
                                            }
                                            checkboxes={false}
                                        />
                                    </Box>
                                ) : (
                                    <Box height="400px">
                                        <ProfilesTable
                                            checkboxes={false}
                                            profiles={
                                                reflexDetails.DestProfile === null
                                                    ? []
                                                    : reflexDetails.DestProfile
                                            }
                                            setProfiles={(v) =>
                                                setReflexDetails({
                                                    ...reflexDetails,
                                                    DestProfile: v,
                                                })
                                            }
                                        />
                                    </Box>
                                )}
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
                <Divider sx={{ p: 1, my: 1 }} />
                <FormControl sx={{ flexDirection: "row", flexWrap: "wrap" }}>
                    <FormControl sx={{ minWidth: 200, m: 1 }} size="small">
                        <TextField
                            select
                            required
                            size="small"
                            label="Reflex When"
                            value={
                                reflexDetails.ReflexRangeType === undefined
                                    ? ""
                                    : reflexDetails.ReflexRangeType
                            }
                            name="ReflexRangeType"
                            key="ReflexRangeType"
                            onChange={updateReflex}
                        >
                            <MenuItem key={0} value={"Normal"}>
                                Normal Result
                            </MenuItem>
                            <MenuItem key={1} value={"Abnormal"}>
                                Abnormal Result
                            </MenuItem>
                            <MenuItem key={2} value={"Critical"}>
                                Critical Result
                            </MenuItem>
                        </TextField>
                    </FormControl>
                </FormControl>
                <Divider sx={{ p: 1, my: 1 }} />
                {!forAllFacs && (
                    <>
                        <Typography variant="h5">Facilities</Typography>
                        <Box height="400px">
                            <FacilitiesTable
                                getFacilities={getFacilities}
                                checkboxes={true}
                                selectedFacilities={
                                    reflexDetails.Facilities === undefined
                                        ? []
                                        : reflexDetails.Facilities
                                }
                                setSelectedFacilities={(v) =>
                                    setReflexDetails({
                                        ...reflexDetails,
                                        Facilities: v,
                                    })
                                }
                            />
                        </Box>
                    </>
                )}
                <FormControl sx={{ minWidth: 200, m: 1 }} size="small">
                    <ReferenceRange
                        type={reflexDetails.ReflexRangeType}
                        ranges={ranges}
                        setRanges={setRanges}
                    />
                </FormControl>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
                <Button variant="outlined" color="primary" onClick={saved}>
                    Back
                </Button>
                <Button
                    variant="contained"
                    color="success"
                    onClick={(e) => {
                        e.preventDefault();
                        const ele = document.getElementById("reflex-form");
                        const chk_status = ele.checkValidity();
                        ele.reportValidity();
                        if (chk_status) {
                            saveReflex(reflexDetails, ranges)
                                .then(() => saved())
                        }
                    }}
                >
                    Save
                </Button>
            </Stack>
        </Box>
    );
}
