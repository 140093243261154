import { useState, useEffect } from "react";

const BASE_URL = process.env.REACT_APP_API_PROXY;
const VERSION = "/v1";

export async function apiFetch(url, method = "GET", body = null, noVersion = false, opts = {}, signal = null) {
    const options = {
        method,
        headers: {
            Accept: "application/json, application/pdf, text/csv, image/png",
            "Content-Type": "application/json",
        },
        credentials: "include",
        signal: signal,
        ...opts
    };

    if (body) {
        options.body = JSON.stringify(body);
    }

    let response;

    if (noVersion) {
        response = await fetch(`${BASE_URL}${url}`, options);
    } else {
        response = await fetch(`${BASE_URL}${VERSION}${url}`, options);
    }

    if (!response.ok) {
        try {
            let data;
            try {
                data = await response.json()
            } catch {
                throw new Error("API request failed");
            }
            if (data.error) {
                throw new Error(data.error)
            } else {
                throw new Error("API request failed");
            }
        } catch (e) {
            throw new Error(e)
        }
    }

    const contentType = response.headers.get("content-type");

    if (contentType && contentType.indexOf("application/json") !== -1) {
        return await response.json();
    } else if (contentType && contentType.indexOf("text/plain") !== -1) {
        return await response.blob();
    } else if (contentType && contentType.indexOf("application/pdf") !== -1) {
        return await response.blob();
    } else if (contentType && contentType.indexOf("text/csv") !== -1) {
        return await response.blob();
    } else if (contentType && contentType.indexOf("image/png") !== -1) {
        return await response.blob();
    } else if (contentType && contentType.indexOf("image/jpeg") !== -1) {
        return await response.blob();
    } else {
        throw new Error("Unknown response type");
    }
}

export function useFetch(url, method = "GET", body = null) {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [refetch, setRefetch] = useState(0);

    const refetchFunc = () => setRefetch((prevValue) => setRefetch(prevValue + 1))

    useEffect(() => {
        let ignore = false;

        const fetchData = async () => {
            try {
                const result = await apiFetch(url, method, body);
                if (!ignore) {
                    setData(result);
                }
            } catch (error) {
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();

        return () => { ignore = true; }
    }, [url, method, body, refetch]);

    return { data, error, isLoading, refetch: refetchFunc };
}


// =======================================================================
// ATTACHMENTS
// =======================================================================

export function getAttachment(uuid) {
    return apiFetch("/attachments/" + uuid);
}

// =======================================================================
// ELIGIBLITY
// =======================================================================

export function getEligibilityLog() {
    return apiFetch("/eligibilityLog");
}
