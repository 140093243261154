import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { Button, Autocomplete, MenuItem, Stack, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import { updateUser, addUser, getRoles } from "../services/users";
import { getLaboratories } from "../services/tests";
import { getFacilities } from "../services/facilities";
import { getProviders } from "../services/providers";
import { getCollectors } from "../services/collectors";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { DisplayFormWarning } from "../services/util";
import ErrorAlert from "./ErrorAlert";
import { CustomCheckboxField, CustomTextField } from "./CustomTextField";
import { FormStack } from "./FieldStack";
import { apiFetch } from "../services/fetch";

export default function UserInput({ initialValues, reset }) {
    const [allFacilities, setAllFacilities] = useState([]);
    const [allProviders, setAllProviders] = useState([]);
    const [allCollectors, setAllCollectors] = useState([]);
    const [allLaboratories, setAllLaboratories] = useState([]);
    const [organizations, setOrganizations] = useState([]);
    const [allRoles, setAllRoles] = useState([]);
    const [error, setError] = useState();
    const [type, setType] = useState("Facility");

    useEffect(() => {
        getLaboratories()
            .then((p) => {
                if (!p.ok) {
                    throw new Error("Failed to load laboratories");
                }
                return p.json();
            })
            .then((p) => {
                p = p.filter((lab) => !lab.Reference);
                setAllLaboratories(p)
            });

        getFacilities().then((p) => {
            setAllFacilities(p);
        });
        getRoles().then((p) => {
            setAllRoles(p);
        });
        getProviders().then((p) => {
            setAllProviders(p);
        });
        getCollectors().then((p) => {
            setAllCollectors(p);
        });

        const init = async () => {
            try {
                const response = await apiFetch('/organizations')
                setOrganizations(response)
            } catch (error) {
                console.log(error)
            }

        }

        init()


    }, []);

    const roleColumns = [
        {
            field: "Name",
            headerName: "Name",
            width: 200,
        },
    ];

    const handleSubmit = (values) => {
        console.log(values);
        if (values.ID === undefined) {
            if (values.Type === "Facility" && values.Facilities.length < 1) {
                setError({ message: "Please assign user to one or more facilites" })
                return
            }
            // create new facility
            console.log("creating", values);
            addUser(values)
                .catch((err) => setError(err))
                .finally(() => reset());
        } else {
            // update existing facility
            console.log("updating");
            updateUser(values, values.ID)
                .catch((err) => setError(err))
                .finally(() => reset());
        }
    };

    const validationSchema = Yup.object().shape({
        Email: Yup.string().email().required(),
        FirstName: Yup.string().required(),
        LastName: Yup.string().required(),
        ProviderID: Yup.number().notRequired(),
        CollectorID: Yup.number().notRequired(),
        LaboratoryID: Yup.number().required(),
        Type: Yup.string().oneOf(["Lab", "Facility"]).required(),
        Facilities: Yup.array().of(Yup.number().notRequired()).notRequired(),
        Roles: Yup.array().of(Yup.number().required()).required(),
        Notifications: Yup.array().notRequired(),
    });

    const facilityColumns = [
        {
            field: "ID",
            headerName: "ID",
            width: 80,
        },
        {
            field: "Name",
            headerName: "Name",
            width: 200,
        },
    ];

    const orgColumns = [
        {
            field: "ID",
            headerName: "ID",
            width: 80,
        },
        {
            field: "Name",
            headerName: "Name",
            width: 200,
        },
    ];


    return (
        <Box>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({
                    values,
                    handleChange,
                    errors,
                    handleBlur,
                    touched,
                    setFieldValue,
                }) => (
                    <Form>
                        {console.log(values)}
                        {console.log(errors)}
                        {console.log(touched)}
                        <Typography sx={{ my: 2 }} variant="h5" gutterBottom>
                            User
                        </Typography>
                        <FormStack>
                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label">
                                    User Type
                                </FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="Type"
                                    value={values?.Type}
                                    onChange={(event) => {
                                        handleChange(event);
                                        // reset roles & facilities
                                        setFieldValue("Roles", []);
                                        setFieldValue("Facilities", []);
                                    }}
                                    onBlur={handleBlur}
                                >
                                    <FormControlLabel
                                        value="Lab"
                                        control={<Radio />}
                                        label="Lab"
                                    />
                                    <FormControlLabel
                                        value="Facility"
                                        control={<Radio />}
                                        label="Facility"
                                    />
                                </RadioGroup>
                            </FormControl>
                            <Stack direction={{ sm: "column", md: "row" }} spacing={{ xs: 1, sm: 1, md: 1 }}>
                                <CustomTextField
                                    values={values}
                                    required={true}
                                    onBlur={handleBlur}
                                    name="FirstName"
                                    label="First Name"
                                    handleChange={handleChange}
                                    touched={touched}
                                    errors={errors}
                                />
                                <CustomTextField
                                    values={values}
                                    required={true}
                                    onBlur={handleBlur}
                                    name="LastName"
                                    label="Last Name"
                                    handleChange={handleChange}
                                    touched={touched}
                                    errors={errors}
                                />
                                <CustomTextField
                                    values={values}
                                    required={true}
                                    name="Email"
                                    handleChange={handleChange}
                                    onBlur={handleBlur}
                                    touched={touched}
                                    errors={errors}
                                    inputProps={{ type: "email" }}
                                />
                                <FormControl sx={{ minWidth: 200, m: 1 }} size="small">
                                    <TextField
                                        size="small"
                                        select
                                        required
                                        label="Laboratory"
                                        error={
                                            touched.LaboratoryID &&
                                            Boolean(errors.LaboratoryID)
                                        }
                                        helperText={
                                            touched.LaboratoryID && errors.LaboratoryID
                                        }
                                        value={
                                            values.LaboratoryID === undefined
                                                ? ""
                                                : values.LaboratoryID
                                        }
                                        name="LaboratoryID"
                                        key="Laboratory"
                                        onChange={(v) =>
                                            setFieldValue('LaboratoryID', v.target.value)
                                        }
                                    >
                                        {allLaboratories.map((option) => (
                                            <MenuItem key={option.ID} value={option.ID}>
                                                {option.Name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </FormControl>
                                <CustomCheckboxField
                                    checked={values?.LinkToProvider || false}
                                    name="LinkToProvider"
                                    label="Link To Provider"
                                    disabled={values?.LinkToCollector}
                                    onChange={(_, v) => {
                                        if (v) {
                                            setFieldValue('Collector', null)
                                            setFieldValue('CollectorID', null)
                                        } else {
                                            setFieldValue('Provider', null)
                                            setFieldValue('ProviderID', null)
                                        }
                                        setFieldValue('LinkToProvider', v)
                                    }
                                    }
                                />
                                <CustomCheckboxField
                                    checked={values?.LinkToCollector || false}
                                    name="LinkToCollector"
                                    label="Link To Collector"
                                    disabled={values?.LinkToProvider}
                                    onChange={(_, v) => {
                                        if (v) {
                                            // clear provider link
                                            setFieldValue('Provider', null)
                                            setFieldValue('ProviderID', null)
                                        } else {
                                            setFieldValue('Collector', null)
                                            setFieldValue('CollectorID', null)
                                        }
                                        setFieldValue('LinkToCollector', v)
                                    }
                                    }
                                />
                            </Stack>
                            {values?.LinkToProvider &&
                                <Autocomplete
                                    required
                                    disabled={!values?.LinkToProvider}
                                    value={values?.Provider || null}
                                    options={allProviders}
                                    isOptionEqualToValue={(option, value) =>
                                        option.id === value.id
                                    }
                                    getOptionLabel={(row) => row.NPI + " - " + row.FirstName + " " + row.LastName}
                                    onChange={(_, newValue) => { setFieldValue('Provider', newValue); setFieldValue('ProviderID', newValue.ID) }}
                                    sx={{ minWidth: "200px" }}
                                    renderInput={(params) => {
                                        return (
                                            <TextField
                                                required
                                                {...params}
                                                error={
                                                    touched?.Provider &&
                                                    Boolean(errors?.Provider)
                                                }
                                                helperText={
                                                    touched?.Provider &&
                                                    errors?.Provider
                                                }
                                                size="small"
                                                label="Provider"
                                            />
                                        );
                                    }}
                                />
                            }
                            {values?.LinkToCollector &&
                                <Autocomplete
                                    required
                                    disabled={!values?.LinkToCollector}
                                    value={values?.Collector || null}
                                    options={allCollectors}
                                    isOptionEqualToValue={(option, value) =>
                                        option.id === value.id
                                    }
                                    getOptionLabel={(row) => row.FirstName + " " + row.LastName}
                                    onChange={(_, newValue) => { setFieldValue('Collector', newValue); setFieldValue('CollectorID', newValue.ID) }}
                                    sx={{ minWidth: "200px" }}
                                    renderInput={(params) => {
                                        return (
                                            <TextField
                                                required
                                                {...params}
                                                error={
                                                    touched?.Collector &&
                                                    Boolean(errors?.Collector)
                                                }
                                                helperText={
                                                    touched?.Collector &&
                                                    errors?.Collector
                                                }
                                                size="small"
                                                label="Collector"
                                            />
                                        );
                                    }}
                                />
                            }
                            <ErrorAlert error={error?.message} />
                            <Typography sx={{ my: 2 }} variant="h5" gutterBottom>
                                Roles
                            </Typography>
                            <Box height="300px">
                                <DataGridPro
                                    getRowId={(row) => row.ID}
                                    hideFooter
                                    keepNonExistentRowsSelected
                                    density="compact"
                                    rows={allRoles.filter(
                                        (row) => row.Type === values?.Type
                                    )}
                                    columns={roleColumns}
                                    pageSize={15}
                                    rowSelectionModel={values?.Roles}
                                    onRowSelectionModelChange={(newValues) =>
                                        setFieldValue("Roles", newValues)
                                    }
                                    pageSizeOptions={[15]}
                                    checkboxSelection
                                    disableRowSelectionOnClick
                                />
                            </Box>

                            {values?.Type === "Facility" &&
                                <>
                                    <Typography
                                        sx={{ my: 2 }}
                                        variant="h5"
                                        gutterBottom
                                    >
                                        Organizations
                                    </Typography>
                                    <Box height="300px">
                                        <DataGridPro
                                            getRowId={(row) => row.ID}
                                            density="compact"
                                            keepNonExistentRowsSelected
                                            rows={organizations}
                                            columns={orgColumns}
                                            pageSize={15}
                                            components={{ Toolbar: GridToolbar }}
                                            componentsProps={{
                                                toolbar: {
                                                    showQuickFilter: true,
                                                    quickFilterProps: {
                                                        debounceMs: 500,
                                                    },
                                                },
                                            }}
                                            rowSelectionModel={values?.Organizations}
                                            onRowSelectionModelChange={(newValues) =>
                                                setFieldValue(
                                                    "Organizations",
                                                    newValues
                                                )
                                            }
                                            pageSizeOptions={[15]}
                                            checkboxSelection
                                            disableRowSelectionOnClick
                                        />
                                    </Box>
                                    <Typography
                                        sx={{ my: 2 }}
                                        variant="h5"
                                        gutterBottom
                                    >
                                        Facilities
                                    </Typography>
                                    <Box height="300px">
                                        <DataGridPro
                                            getRowId={(row) => row.ID}
                                            density="compact"
                                            keepNonExistentRowsSelected
                                            rows={allFacilities}
                                            columns={facilityColumns}
                                            pageSize={15}
                                            components={{ Toolbar: GridToolbar }}
                                            componentsProps={{
                                                toolbar: {
                                                    showQuickFilter: true,
                                                    quickFilterProps: {
                                                        debounceMs: 500,
                                                    },
                                                },
                                            }}
                                            rowSelectionModel={values?.Facilities}
                                            onRowSelectionModelChange={(newValues) =>
                                                setFieldValue(
                                                    "Facilities",
                                                    newValues
                                                )
                                            }
                                            pageSizeOptions={[15]}
                                            checkboxSelection
                                            disableRowSelectionOnClick
                                        />
                                    </Box>
                                </>
                            }
                        </FormStack>
                        {Object.keys(errors).length > 0
                            && DisplayFormWarning(errors, touched)}
                        <Stack direction="row" justifyContent="space-between">
                            <Button
                                variant="outlined"
                                sx={{ my: 2 }}
                                onClick={reset}
                            >
                                Back
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{ my: 2 }}
                                onClick={() => console.log(values)}
                            >
                                Save
                            </Button>
                        </Stack>
                    </Form>
                )}
            </Formik>
        </Box>
    );
}
